<!-- Why Choose Us Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 align-self-center">
        <div class="mb-4 mb-lg-0">
          <div class="p-2 bg-soft-primary d-inline-block rounded mb-4">
            <div class="icon-xxl uim-icon-primary"><span class="uim-svg"><svg xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24" width="1em">
                  <polygon class="uim-primary" points="12 12.3 3.5 7.05 12 1.8 20.5 7.05 12 12.3"></polygon>
                  <polygon class="uim-quaternary" points="12 22.2 12 12.3 20.5 7.05 20.5 16.95 12 22.2"></polygon>
                  <polygon class="uim-tertiary" points="12 22.2 3.5 16.95 3.5 7.05 12 12.3 12 22.2"></polygon>
                </svg></span>
            </div>
          </div>
          <h3 class="">¿Por qué elegirnos?</h3>
          <p class="text-muted mb-4">En Helix Sys, creamos soluciones a medida que combinan tecnología y diseño para impulsar el éxito de tu negocio.</p>
          <!-- <a href="javascript: void(0);" class="btn btn-outline-primary">Learn More</a> -->
        </div>
      </div>
      <div class="col-lg-8 align-self-center">
        <div class="row">
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4 mt-md-5">
              <div class="wc-box-icon">
                <i class="mdi mdi-collage"></i> 
              </div>
              <h5 class="fw-bold mb-2 wc-title mt-4">Diseño Intuitivo</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Creamos interfaces amigables y fáciles de usar, garantizando una experiencia de usuario óptima en cada proyecto.</p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-trending-up"></i>
              </div>
              <h5 class="fw-bold mb-2 wc-title mt-4">Tecnología de Vanguardia</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Utilizamos las últimas tecnologías para asegurar que nuestras soluciones sean eficientes, seguras y escalables.</p>
            </div>
          </div>
          <div class="col-md-6">
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-account-convert"></i>
              </div>
              <h5 class="fw-bold mb-2 wc-title mt-4">Soluciones Personalizadas</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">Nos adaptamos a las necesidades específicas de tu negocio, creando sistemas y aplicaciones que realmente resuelven tus desafíos.</p>
            </div>
            <div class="wc-box rounded text-center wc-box-primary p-4">
              <div class="wc-box-icon">
                <i class="mdi mdi-arrow-up-bold-box-outline"></i>
              </div>
              <h5 class="fw-bold mb-2 wc-title mt-4">Compromiso con la Calidad</h5>
              <p class="text-muted mb-0 font-size-15 wc-subtitle">En Helix Sys, nos comprometemos a entregar productos de alta calidad que superen tus expectativas y aporten valor real a tu negocio.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Why Choose Us End -->
